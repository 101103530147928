// the Uniswap Default token list lives here
// testnet
// export const DEFAULT_TOKEN_LIST_URL =
//   'https://raw.githubusercontent.com/chip-swap/chipswap_default_token_list/develop/testnet.json?token=AJQHSRTBAUH7XYNU65QEAF27XHBBC'
// mainnet
// export const DEFAULT_TOKEN_LIST_URL =
//   'https://raw.githubusercontent.com/chip-swap/chipswap_default_token_list/develop/mainnet.json?token=AJQHSRS5E4OQBZ7QUA4ONYK7XMZMQ'

export const DEFAULT_TOKEN_LIST_URL =
  'https://raw.githubusercontent.com/sunshineswap/default-token-list/main/mainnet.json'
export const TESTNET_TOKEN_LIST_URL =
  'https://raw.githubusercontent.com/sunshineswap/default-token-list/main/testnet.json'

export const DEFAULT_LIST_OF_LISTS: string[] = [
  DEFAULT_TOKEN_LIST_URL,
  TESTNET_TOKEN_LIST_URL,
  // 't2crtokens.eth', // kleros
  // 'tokens.1inch.eth', // 1inch
  // 'synths.snx.eth',
  // 'tokenlist.dharma.eth',
  // 'defi.cmc.eth',
  // 'erc20.cmc.eth',
  // 'stablecoin.cmc.eth',
  // 'tokenlist.zerion.eth',
  // 'tokenlist.aave.eth',
  // 'https://tokens.coingecko.com/uniswap/all.json',
  // 'https://app.tryroll.com/tokens.json',
  // 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
  // 'https://defiprime.com/defiprime.tokenlist.json',
  // 'https://umaproject.org/uma.tokenlist.json'
]
