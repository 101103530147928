import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'

import { STAKING_REWARDS_INFO, useStakingInfo, useStakingInfo2, useStakingInfo3 } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import PoolCard2 from '../../components/earn/PoolCard2'
import PoolCard3 from '../../components/earn/PoolCard3'
import { RowBetween } from '../../components/Row'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
`

const CardWrapper = styled.div`
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`
const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
    text-align: cneter;
  `};
`

export default function Earn() {

  const { chainId } = useActiveWeb3React()

  const stakingInfos = useStakingInfo()
  const stakingInfos2 = useStakingInfo2()
  const stakingInfos3 = useStakingInfo3()

  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)

  return (

    <PageWrapper gap="lg" justify="center">

          <AutoColumn gap="lg" style={{ width: '100%' }}>
            <TitleRow padding={'0'}>
              <TYPE.mediumHeader style={{ justifySelf: 'flex-start' }}>
                Farming
              </TYPE.mediumHeader>
            </TitleRow>
          </AutoColumn>

      <CardWrapper>
        <PoolSection>

        {stakingRewardsExist && stakingInfos3?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            'No active rewards'
          ) : (
            stakingInfos3?.map(stakingInfo => {
              return <PoolCard3 key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
            })
          )}

          <hr/>

          {stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            'No active rewards'
          ) : (
            stakingInfos?.map(stakingInfo => {
              return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
            })
          )}

          <hr/>

          {stakingRewardsExist && stakingInfos2?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            'No active rewards'
          ) : (
            stakingInfos2?.map(stakingInfo => {
              return <PoolCard2 key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
            })
          )}

        </PoolSection>
      </CardWrapper>

    </PageWrapper>
  )
}
