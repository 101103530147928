import { ChainId, CurrencyAmount, JSBI, Pair, Token, TokenAmount } from '@chip-swap/sdk'
import { useMemo } from 'react'
import { SUNSHINE, VRSX } from '../../constants'
import { Interface } from '@ethersproject/abi'
import STAKING_ABI from '../../constants/abis/staking.json'
import { useActiveWeb3React } from '../../hooks'
import { useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'

export const STAKING_GENESIS = 1600387200

export const REWARDS_DURATION_DAYS = 60

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
}

export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
  }[]
} = {
  [ChainId.BSC]: [
    {
      tokens: [VRSX[ChainId.BSC], SUNSHINE[ChainId.BSC]],
      stakingRewardAddress: '0x630AC46226DaD9a16E0E631d5A6aA1574ad8fd9e'
    },
  ],
  [ChainId.BSCTEST]: [
    {
      tokens: [VRSX[ChainId.BSCTEST], SUNSHINE[ChainId.BSCTEST]],
      stakingRewardAddress: '0x2AD321afda4a53746eCDe6b8f4FF2C7Ae267fCFe'
    },
  ],
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const sunshine = chainId ? SUNSHINE[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  const stakingABI = new Interface(STAKING_ABI); 
  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, stakingABI, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, stakingABI, 'earned', accountArg)

  return useMemo(() => {
    if (!chainId || !sunshine) return []

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        const stakedAmount = new TokenAmount(tokens[0], JSBI.BigInt(balanceState?.result?.[0] ?? 0))

        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          earnedAmount: new TokenAmount(sunshine, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          stakedAmount: stakedAmount,
        })

      }
      return memo
      
    }, [])
  }, [balances, chainId, earnedAmounts, info, rewardsAddresses, sunshine])
}

export function useTotalSunshineEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const sunshine = chainId ? SUNSHINE[chainId] : undefined
  const stakingInfos = useStakingInfo()

  return useMemo(() => {
    if (!sunshine) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(sunshine, '0')
      ) ?? new TokenAmount(sunshine, '0')
    )
  }, [stakingInfos, sunshine])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? 'Enter an amount'
  }

  return {
    parsedAmount,
    error
  }
}
