import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'

import { ButtonSecondary } from '../../components/Button'
import { useWalletModalToggle } from '../../state/application/hooks'

// import { TYPE } from '../../theme'
// import { RowBetween } from '../../components/Row'
// import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'

const PageWrapper = styled(AutoColumn)`
  max-width: 1020px;
  width: 100%;
  min-height: 500px;
  padding: 50px;
  background-color: #ee6b00;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 20px;
  `};
`

const TopBg = styled.div`
  background: #ee6b00 url(/images/top.jpg) left top no-repeat;
  background-size: 100% auto;
  min-height: 500px;
  width: 100%;
  padding: 500px 0 20px;

  img {
    max-width: 100%;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    background: #ee6b00 url(/images/top_sp.jpg) left top no-repeat;
    background-size: 100% auto;
    padding: 800px 0 100px;
 `};

`

const ConnectButton = styled(ButtonSecondary)`
  background-color: #3e3a39;
  width: 260px;
  padding: 1rem 0.5rem;
  border-radius: 0;
  border: none;
  color: #fff;
  font-weight: 500;
  margin: 20px 0 0;

  :hover,
  :focus {
    border: none;
    box-shadow: none;
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1.5rem;
  width: fit-content;
  font-weight: 500;
  color: #fff;
`


export default function Home() {

  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()

  return (

    <PageWrapper gap="lg" justify="center">
      <TopBg>
      
        {!account && (
          <ConnectButton onClick={toggleWalletModal}><Text>Connect Wallet</Text></ConnectButton>
        )}

      </TopBg>
    </PageWrapper>
  )
}
