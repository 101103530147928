import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'

import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'


const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  padding-bottom: 100px;
`

export default function Company() {

  return (
    <PageWrapper gap="lg" justify="center">

      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>
          Company
        </TYPE.mediumHeader>
      </RowBetween>

      <table className="">
        <tbody>
          <tr><th>Company Name</th><td>SUNSHINE SWAP.COM Sp. z o.o.</td></tr>
          <tr><th>License registration no.</th><td>Registered in the Polish Register of Virtual Currency Business Activities under number RDWW-442.</td></tr>
        </tbody>
      </table>

    </PageWrapper>
  )
}
