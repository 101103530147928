import React from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'

import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'


const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  padding-bottom: 100px;
`

export default function Airdrop() {

  return (
    <PageWrapper gap="lg" justify="center">

      <RowBetween style={{ gap: '24px' }}>
        <TYPE.mediumHeader style={{ margin: 0 }}>
          Airdrop
        </TYPE.mediumHeader>
      </RowBetween>

      <div className="my-4"><img src="/images/127934.jpg" alt="" /></div>

      <div>
        Airdrop Festival!<br/>
        <br/>
        369,000 free Utility Tokens "SPC" for all the first 1,000 customers!<br/>
        <br/>
        What is the Utility Token SPC?<br/>
        SPC is the central axis of the project's economic zone expansion, and is a utility token that crosses the following three services centered on the project's own DEX (decentralized exchange).<br/>
        <br/>
        Live Streaming Business<br/>
        NFT Market Business<br/>
        GameFi Business<br/>
        Currently, the live-streaming business is steadily underway.<br/>
        <br/>
        The token will also be part of the yield farming currency pair in the DeFi (decentralized finance) service to be launched on the DEX, and will be the axis of wealth for its holders.<br/>
        <br/>
        Advantages of owning an SPC<br/>
        <br/>
        (1) Opportunity to participate in IDO<br/>
        Special people who participate in this airdrop project will be offered the chance to earn the Governance Token SS.<br/>
        SS is a revolutionary and attractive token that allows you to earn ongoing income from your business by yield farming.<br/>
        <br/>
        (2) Earn interest through yield farming<br/>
        SS and SPC tokens are the twin pillars of our project and will grow together.<br/>
        Yield farming is the process of depositing your crypto assets in a DeFi operated DEX (decentralized exchange) and receiving rewards.<br/>
        <br/>
        Take advantage of this opportunity and get your SPC tokens for free!!!
      </div>

    </PageWrapper>
  )
}
