import React, { useState, useCallback, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
// import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { HamburgerElastic } from 'react-animated-burgers'

import Logo from '../../assets/images/dps.png'
import LogoDark from '../../assets/images/dps.png'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'

import Settings from '../Settings'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'

const HeaderBg = styled.div`
  display: block;
  width: 100%;
`

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  z-index: 2;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 0.5rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  padding: 1rem;
  background-color: #3e3a39;


`
{/*
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 0;
    background-color: #3e3a39;
  `};
*/}

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   display: none;
  `};

`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
`};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const HamburgerMenu = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: block;
    text-align: center;
  `};

`

const HamburgerLinks = styled.div`
  padding: 1rem 0 1rem;
  justify-content: flex-end;
  display: block;
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  text-decoration: none;
  padding: 0 0 0 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
    padding: 0 0 0 1rem;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  font-family: serif;
  color: #000;
  display: flex;
  line-height: 32px;

  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  font-size: 20px;
  width: fit-content;
  margin: 0 12px;
  font-weight: bold;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 0.85rem;
  `};

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: #aaa;
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.white)};
  }
`

const StyledLink = styled.a`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  font-size: 20px;
  width: fit-content;
  margin: 0 12px;
  font-weight: bold;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 0.85rem;
  `};

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.white)};
  }
`

const StyledMobileNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  font-size: 1.25rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;
  padding: 10px 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 0.85rem;
  `};

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.white)};
  }

`
const StyledMobileLink = styled.a`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  font-size: 1.25rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;
  padding: 10px 0;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 0.85rem;
  `};

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.white)};
  }

`

const IconText = styled.span`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`


export default function Header() {

  const { account } = useActiveWeb3React()
  // const { t } = useTranslation()
  const [isDark, toggleSetDarkMode] = useDarkModeManager()

  useEffect(() => {
    if(isDark) {
      toggleSetDarkMode();
    }
  }, [])

  const [isActive, setIsActive] = useState(false)
  const toggleButton = useCallback(() => setIsActive(prevState => !prevState), [])

  return (
    <HeaderBg>
    <HeaderFrame>

      <HeaderRow>

        <HamburgerMenu>
          <HamburgerElastic buttonWidth={20} barColor="black" {...{ isActive, toggleButton }} />
        </HamburgerMenu>

        <Title href=".">
          <UniIcon>
            <img height={'32px'} src={isDark ? LogoDark : Logo} alt="logo" />
            <IconText>SunshineSwap</IconText>
          </UniIcon>
        </Title>

        <HeaderLinks>
          <StyledNavLink id={`home-nav-link`} to={'/home'}>
            Home
          </StyledNavLink>
          <StyledLink id="cf-nav-link" href="https://exchange.sunshineswap.com/#/swap" rel="noopener noreferrer">
            Swap
          </StyledLink>
          <StyledLink id="cf-nav-link" href="https://exchange.sunshineswap.com/#/pool" rel="noopener noreferrer">
            Pool
          </StyledLink>
          <StyledNavLink id={`home-nav-link`} to={'/sun'}>
            Farming
          </StyledNavLink>
          <StyledNavLink id={`home-nav-link`} to={'/staking'}>
            Staking
          </StyledNavLink>
          <StyledNavLink id={`home-nav-link`} to={'/airdrop'}>
            Airdrop
          </StyledNavLink>
          <StyledNavLink id={`home-nav-link`} to={'/company'}>
            Company
          </StyledNavLink>
          {/*
          <StyledNavLink id={`cf-nav-link`} to={'/swap'}>
            {t('swap')}
          </StyledNavLink>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/add'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/create') ||
              pathname.startsWith('/find')
            }
          >
            {t('pool')}
          </StyledNavLink>
          <StyledNavLink id={`stake-nav-link`} to={'/sun'}>
            Farm
          </StyledNavLink>
          */}
        </HeaderLinks>

      </HeaderRow>

      <HeaderControls>
        <HeaderElement>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElementWrap>
          <Settings />
        </HeaderElementWrap>
      </HeaderControls>
    </HeaderFrame>

    {isActive && (
      <HeaderRow>
        <HamburgerLinks>
          <StyledMobileNavLink id={`home-nav-link`} to={'/home'}>
            Home
          </StyledMobileNavLink>
          <StyledMobileLink id="cf-nav-link" href="https://exchange.sunshineswap.com/#/swap" rel="noopener noreferrer">
            Swap
          </StyledMobileLink>
          <StyledMobileLink id="cf-nav-link" href="https://exchange.sunshineswap.com/#/pool" rel="noopener noreferrer">
            Pool
          </StyledMobileLink>
          <StyledMobileNavLink id={`home-nav-link`} to={'/sun'}>
            Farming
          </StyledMobileNavLink>
          <StyledMobileNavLink id={`home-nav-link`} to={'/staking'}>
            Staking
          </StyledMobileNavLink>
          <StyledMobileNavLink id={`home-nav-link`} to={'/airdrop'}>
            Airdrop
          </StyledMobileNavLink>
          <StyledMobileNavLink id={`home-nav-link`} to={'/company'}>
            Company
          </StyledMobileNavLink>
        </HamburgerLinks>
      </HeaderRow>
    )}

    </HeaderBg>
  )
}
