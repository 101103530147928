import { ChainId, JSBI, Percent, Token, WBNB } from '@chip-swap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { binanceconnect, fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'

// https://bscscan.com/address/0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F
export const ROUTER_ADDRESS = '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F'
// export const ROUTER_ADDRESS = '0x252E740243d2b63d413f6E76B478A3075b97ac9a'
// export const ROUTER_ADDRESS = '0x92394d84697865838F5B8ab675BC39D0315D426D'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const BUSD = new Token(ChainId.BSC, '0xe9e7cea3dedca5984780bafc599bd69add087d56', 18, 'BUSD', 'BUSD')
export const USDT = new Token(ChainId.BSC, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'USDT')
export const CAKE = new Token(ChainId.BSC, '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82', 18, 'CAKE', 'CAKE')
export const LTC = new Token(ChainId.BSC, '0x4338665cbb7b2485a8855a139b75d5e34ab0db94', 18, 'WLTC', 'WLTC')

// TODO this is only approximate, it's actually based on blocks
export const PROPOSAL_LENGTH_IN_DAYS = 7

// TODO: replace the contract address of bsc
export const GOVERNANCE_ADDRESS = '0x756556D896D6dA1b2355667Eda5BCBf05B872aDD'

export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.BSC]: new Token(ChainId.BSC, '0xa25fc408ef05321103243557c851101f9acee608', 18, 'Sunshine', 'SS'),
  [ChainId.BSCTEST]: new Token(ChainId.BSCTEST, '0x00002e4fc8fc0404b9f6207085c7f47a5e6d1125', 18, 'Sunshine', 'SS')
}

export const VRSX: { [chainId in ChainId]: Token } = {
  [ChainId.BSC]: new Token(ChainId.BSC, '0xF23A5674686DB67Ea76DC5315AdfC41D58eE3c22', 18, 'VRSX', 'VRSX'),
  [ChainId.BSCTEST]: new Token(ChainId.BSCTEST, '0xe2Ab1d0782869B420642CF0353da1b00988002e3', 18, 'VRSX', 'VRSX')
}

export const SUNSHINE: { [chainId in ChainId]: Token } = {
  [ChainId.BSC]: new Token(ChainId.BSC, '0xc00F63929a4ED114d66fcb150706193bab1742e5', 18, 'Sunshine', 'SS'),
  [ChainId.BSCTEST]: new Token(ChainId.BSCTEST, '0x00D7E8aec03a6971b88F43f61875f2F5351c4073', 18, 'Sunshine', 'SS')
}

export const SPC: { [chainId in ChainId]: Token } = {
  [ChainId.BSC]: new Token(ChainId.BSC, '0x1f01F244634D7a01d32742Ba6017d23Db4dba6D7', 18, 'SPC', 'SPC'),
  [ChainId.BSCTEST]: new Token(ChainId.BSCTEST, '0x1f01F244634D7a01d32742Ba6017d23Db4dba6D7', 18, 'SPC', 'SPC'),
}




// TODO: specify merkle distributor for mainnet
// TODO: replace the contract address of bsc
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  // https://etherscan.io/address/0x090d4613473dee047c3f2706764f49e0821d256e#code
  [ChainId.BSC]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}

const WBNB_ONLY: ChainTokenList = {
  [ChainId.BSC]: [WBNB[ChainId.BSC],BUSD,SUNSHINE[ChainId.BSC]],
  //[ChainId.BSC]: [BUSD],
  [ChainId.BSCTEST]: [WBNB[ChainId.BSCTEST]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WBNB_ONLY,
  [ChainId.BSCTEST]: [...WBNB_ONLY[ChainId.BSCTEST]]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {
    //[BUSD.address]: [RON, WBNB[ChainId.BSCTEST]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WBNB_ONLY,
  [ChainId.BSCTEST]: [...WBNB_ONLY[ChainId.BSCTEST]]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WBNB_ONLY,
  [ChainId.BSCTEST]: [...WBNB_ONLY[ChainId.BSCTEST]]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  /*
  [ChainId.BSCTEST]: [
    [USDT, WBTC],
    [LINK, USDT]
  ]
  */
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  BINANCE_CONNECT: {
    connector: binanceconnect,
    name: 'Binance Smart Chain Wallet',
    iconName: 'binance.png',
    description: 'Use Binance Smart Chain Wallet.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
